<template>
  <b-card
    class="card-custom gutter-b"
    no-body
  >
    <!--begin::card-header-->
    <div class="card-header flex-wrap border-0 pt-6 pb-0">
      <b-card-title>
        <h2 class="card-label">
          {{ trans('nav.aside.products.all') }}
          <span class="d-block text-muted pt-2 font-size-sm">
            {{ trans('products.index.desc') }}
          </span>
        </h2>
      </b-card-title>
      <!-- card-toolbar placeholder: add toolbar actions here :: <div class="card-toolbar">-->
    </div>
    <!--end::card-header-->

    <!--begin::card-body-->
    <div class="card-body" style="min-height: 80px;">
      <b-table
        id="products"
        primary-key="productid"
        :fields="fields"
        :items="items"
        responsive="md"
      >
        <!--begin::info-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(info)
          class="product-info"
        >
          <div class="list-item loading rounded"></div>
        </template>
        <!-- {{ data.item.info }} -->
        <template
          v-else
          v-slot:cell(info)="data"
        >
          <div class="d-flex align-items-center">
            <!--begin::product-image-->
            <div class="symbol symbol-60 symbol-sm flex-shrink-0 p-1 bg-light">
              <img :src="data.item.info.img" alt="" />
            </div>
            <!--end::product-image-->

            <!--begin::product-details-->
            <div class="ml-3">
              <span class="text-dark-75 font-weight-bold line-height-sm d-block pb-2">
                {{ truncate(data.item.info.name, 60) }}
              </span>
              <span class="text-muted">
                {{ truncate(data.item.info.desc, 40) }}
              </span>
            </div>
            <!--end::product-details-->
          </div>
        </template>
        <!--end::info-->

        <!--begin::createdon-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(createdon)
          class="log-createdon"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(createdon)="data"
        >
          {{ momentFullDate(data.item.createdon) }} @ {{ momentHoursMinutesSeconds(data.item.createdon) }}
        </template>
        <!--end::createdon-->

        <!--begin::actions-->
        <template
          v-if="tableProps.loading"
          v-slot:cell(actions)
          class="log-actions"
        >
          <div class="list-item loading rounded"></div>
        </template>

        <template
          v-else
          v-slot:cell(actions)="data"
        >
          <div
            v-if="data.item.info.btns.length > 0"
          >
            <b-btn
              v-for="(btn, bidx) in data.item.info.btns"
              :key="'logentry-view-' + bidx"
              :ref="'logentry-view-' + data.item.id + bidx"
              variant="default"
              size="sm"
              class="btn-text-primary btn-hover-primary"
              :to="btn.to"
            >
              <!-- <span class="svg-icon svg-icon-md">
                <inline-svg :src="'/media/svg/icons/Design/Edit.svg'" />
              </span> -->
              <span>
                {{ btn.label }}
              </span>
            </b-btn>
          </div>
          <span
            v-else
            class="label label-rounded label-inline mr-4 label-lg p-5 label-light-danger"
          >
            Ikke i bruk
          </span>
        </template>
        <!--end::actions-->
      </b-table>

      <!--begin::more-btn-->
      <div class="d-flex justify-content-center align-items-center flex-wrap">
        <div class="d-flex align-items-center py-3">
          <div class="d-flex align-items-center">
            <Loader
              v-if="tableProps.loading"
              :text="trans('products.index.list.loading')"
              loader-class="relative"
              loader-style="margin-right: 80px;"
            ></Loader>
            <b-btn
              v-else-if="tableProps.showMoreBtn"
              ref="products_showmorebtn"
              variant="primary"
              class="py-4 px-6"
              size="sm"
              @click="loadMore(tableProps.lastVisibleDoc)"
            >
              {{ trans('products.index.list.loadMore') }}
            </b-btn>
          </div>
        </div>
      </div>
      <!--end::more-btn-->
    </div>
    <!--end::card-body-->
  </b-card>
</template>


<script>
  import { SETTINGS } from '@src/store/settings'
  import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

  import { trans, transChoice } from '@src/core/helpers/translate'
  import { computed, onBeforeMount, onMounted, ref } from '@vue/composition-api'
  import * as objectPath from 'object-path'
  import useDateTime from '@src/use/datetime'
  import { capitalize, truncate } from '@src/core/helpers/textUtils'

  export default {
    name: 'ProductsIndex',
    components: { Loader: () => import('@src/components/content/Loader') },
    setup (props, { root, refs }) {
      const fb = require('@src/firebaseConfig') // eslint-disable-line global-require
      const { momentFullDate, momentHoursMinutesSeconds } = useDateTime()

      onBeforeMount(() => {
        root.$store.dispatch(SETTINGS.ACTIONS.SET_ACTIVE_SUBMENU, 'Dashboard')
      })

      onMounted(() => {
        root.$store.dispatch(SET_BREADCRUMB, [
          {
            title: trans('nav.aside.products.title'),
            route: 'users'
          },
          { title: trans('nav.aside.products.all') }
        ])
      })

      const tableProps = ref({
        limit: 50,
        loading: true,
        orderBy: 'createdOn', // Hardcoded in backend for now
        sortOrder: 'asc',
        lastVisibleDoc: true,
        showMoreBtn: true
      })

      const tableItems = ref(new Array(tableProps.value.limit).fill({ })) // Create placeholders while loading

      const productInfo = (product) => {
        // ProductImage
        console.log('ProductDATA: ', product)
        let productImage = ''
        const productImageData = objectPath.get(product, 'images.0', {})
        console.log('DATA: ', productImageData)
        const imgPath = objectPath.get(productImageData, 'path', '')
        const imgFile = objectPath.get(productImageData, 'file', '')
        if (imgPath !== '' && imgFile !== '') {
          productImage = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=60&h=60`
        }

        console.log('ProductImage:', productImage)
        
        // Object.keys(productImages).some((productImageKey) => {
        //   const deleted = objectPath.get(productImages, `${ productImageKey }.deleted`, true)
        //   if (!deleted) {
        //     const imgPath = objectPath.get(productImages, `${ productImageKey }.path`, '')
        //     const imgFile = objectPath.get(productImages, `${ productImageKey }.file`, '')
        //     if (imgPath !== '' && imgFile !== '') {
        //       productImage = `${process.env.VUE_APP_IMAGE_CDN_URL}/${ imgPath }/${ imgFile }?fit=crop&w=60&h=60`
        //     }
        //   }

        //   return false
        // })

        // ProductTitle

        // Links to active templates
        let btnCount = 0
        const templateIdsActive = objectPath.get(product, 'templateIdsActive', []).map((templateId) => ({
          to: `/templates/edit/${ templateId }/designer`,
          label: `Template: ${ ++btnCount }`
        }))

        // ProductDescription
        return {
          btns: templateIdsActive,
          img: productImage,
          name: objectPath.get(product, 'name', trans('products.index.list.missingName')),
          desc: objectPath.get(product, 'description', trans('products.index.list.missingName'))
        }
      }

      const items = computed(() => tableItems.value.map((product) => ({
        info: productInfo(product),
        createdon: objectPath.get(product, 'createdOn', {})
      })))

      const fields = computed(() => [
        {
          key: 'info',
          label: capitalize(transChoice('global.product', 0)),
          sortable: false,
          tdClass: 'product-info'
        },
        {
          key: 'createdon',
          label: capitalize(transChoice('global.created', 1)),
          sortable: false,
          tdClass: 'log-createdon',
          thClass: 'log-createdon-head'
        },
        {
          key: 'actions',
          label: capitalize(transChoice('global.action', 0)),
          sortable: false,
          tdClass: 'log-createdon',
          thClass: 'log-createdon-head'
        }
      ])

      const loadMore = async (paginateDoc) => {
        const btn = refs.products_showmorebtn
        if (btn !== undefined) btn.classList.add('spinner', 'spinner-light', 'spinner-right', 'pr-14')

        const getProducts = fb.functions.httpsCallable('backendProductsGetProducts')

        return await getProducts({
          data: {
            limit: tableProps.value.limit,
            orderBy: tableProps.value.orderBy, // disabled - TODO: Fix dates in backend
            sort: tableProps.value.sortOrder,
            paginateDoc
          }
        }).then((res) => {
          if (tableProps.value.loading) tableItems.value = [] // Remove placeholders - can be removed?
          if (paginateDoc === true) tableItems.value = [] // Remove existing products if paginateDoc is true
          tableProps.value.showMoreBtn = Object.keys(objectPath.get(res, 'data.result', {})).length >= tableProps.value.limit // eslint-disable-line max-len

          Object.keys(objectPath.get(res, 'data.result', {})).forEach((doc) => {
            tableItems.value.push(res.data.result[doc])
          })

          if (btn !== undefined) btn.classList.remove('spinner', 'spinner-light', 'spinner-right', 'pr-14')
          tableProps.value.lastVisibleDoc = objectPath.get(res, 'data.paginateDoc.lastVisibleDoc', true)
        })
      }

      // Vue 2 "created" Vue trigger:
      loadMore(tableProps.value.lastVisibleDoc).then(() => {
        tableProps.value.loading = false
      })

      return {
        trans,
        transChoice,
        truncate,
        items,
        fields,
        tableProps,
        loadMore,
        momentFullDate,
        momentHoursMinutesSeconds
      }
    }
  }
</script>

<style lang="scss">
  .list-item {
    overflow: hidden;

    &.loading {
      height: 1.8rem;
    }
  }
</style>
